import React, {useState} from 'react';
import './OffreSitter.css';
import offerImg from './picture.png';


const Offre = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleFormSubmission = (e) => {
    e.preventDefault(); // Prevent the default form submission for validation

    const email = new FormData(e.target).get('EMAIL');
    if (!email.includes('@')) {
      setErrorMessage('Adresse e-mail invalide');
      setSuccessMessage(null);
    } else {
      setErrorMessage(null);
      setSuccessMessage('Formulaire soumis avec succès!');
      // Allow form submission to proceed via iframe
      e.target.submit();
    }
  };

  return (
    <div className="container-offer-sitter" id='container-offer-sitter'>
      <h1 className="heading-sitter">Soyez les premiers à profiter de notre offre de lancement !</h1>
      <div className="content-sitter">
        <div className="left-section-sitter">
          <div className='fisrt-partie-sitter'>
            <h2 className="discount-sitter">-100%</h2>
            <h3 className="offer-sitter">de vos gains garanties sur <br></br> vos 5 premières gardes</h3>
          </div>
          <div className='second-partie-sitter'>
            <div className="banner-sitter">
              <i>Offre à durée limitée.</i>
            </div>
            <p className="download">Profitez de l'offre</p>
            <div className='center-sitter'>
              <form
        className="registration-form-sitter"
        id="sib-form"
        method="POST"
        action="https://d94a76ba.sibforms.com/serve/MUIFAL8PIXTo0QwQQWs0EY466AXmSsvy2ay6ZXOA3Z2T691O9PSgx7LS8Ph77DoOzFFhQX7Ocu_d5BBpZZ1d34JWagIS4LSqst1JbPBPG96JgXrPUxPvsBaYtTagLjT49N36RgELtjmxljT7F_Gqkv8bNiLY-An7o3PFoIlslsuoYCNBSUieFpENV7fPmwhwn4NlAPI57Yn427Yj"
        data-type="subscription"
        onSubmit={handleFormSubmission}
        target="hidden_iframe"
      >
        <div className="form-group-sitter">
          <input
            className="input"
            type="text"
            id="EMAIL"
            name="EMAIL"
            autoComplete="off"
            placeholder="EMAIL"
            data-required="true"
            required
          />
          <button
            type="submit"
            className="sib-form-block__button sib-form-block__button-with-loader"
            onClick={() => {
                        setErrorMessage(null);
                        setSuccessMessage(null);
                      }}
          >
            OK
          </button>
        </div>
        
        <input type="text" name="email_address_check" value="" className="input--hidden" />
        <input type="hidden" name="locale" value="fr" />
      </form>
      {errorMessage && (
        <div className="error-message">
          <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
            <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
          </svg>
          <span>{errorMessage}</span>
        </div>
      )}
      {successMessage && (
        <div className="success-message">
          <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
          </svg>
          <span>{successMessage}</span>
        </div>
      )}
      
      <iframe title="hidden_iframe" name="hidden_iframe" style={{ display: 'none' }}></iframe>

            </div>
          </div>
        </div>
        <div className="image-container-sitter">
          <img src={offerImg} alt="Dog" />
        </div>
      </div>
      
    </div>
  );
};

export default Offre;