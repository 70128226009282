import React from 'react';
import ioss from './Images/ios.png';
import androidd from './Images/android.png';
import './InscriptionSitter.css';

const RegistrationForm = () => {
  

  return (
    <div className='main-i-sitter'>
      <h2>bientôt disponible en téléchargement sur :</h2>
      <div className="store-b-sitter">
        <img src={ioss} alt="App Store" className="store-i-sitter" />
        <img src={androidd} alt="Google Play" className="store-i-sitter" />
      </div>
    </div>
  );
};

export default RegistrationForm;