import React, { useState } from 'react';
import './NavbarSitter.css';
import { Link } from 'react-router-dom'; 
import logo from './Images/logo houses of pets black transparent.png';

const Navbar = () => {
    const [menu, setMenu] = useState("Accueil");
const [isMenuOpen, setIsMenuOpen] = useState(false);

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};

const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
};

return (
    <div className="header-container-sitter">
        <img src={logo} alt="Logo HOP" className='logo-sitter' />
        <div className={`nav-links-sitter ${isMenuOpen ? 'open-sitter' : ''}`}>
            <Link to='/hopper' onClick={() => { setMenu("Accueil"); scrollToTop(); setIsMenuOpen(false); }} className={menu === "Accueil" ? "active-sitter" : ""}>Accueil</Link>
            <a href='#container-offer-sitter' onClick={() => { setMenu("Télécharger application"); setIsMenuOpen(false); }} className={menu === "Télécharger application" ? "active-sitter" : ""}>Télécharger application</a>
            <a href='#propos-container-sitter' onClick={() => { setMenu("Pourquoi HOP"); setIsMenuOpen(false); }} className={menu === "Pourquoi HOP" ? "active-sitter" : ""}>Pourquoi HOP</a>
        </div>
        <div className="right-side-sitter">
            <Link to='/' onClick={() => { setMenu("Sitters"); scrollToTop(); }} className={menu === "Sitters" ? "active-sitter sitters-sitter" : "sitters-sitter"}>Garder mon animal</Link>
            <div className="hamburger-sitter" onClick={toggleMenu}>
                <div className="bar-sitter"></div>
                <div className="bar-sitter"></div>
                <div className="bar-sitter"></div>
            </div>
        </div>
    </div>
);
};

export default Navbar;

