import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import logo from './Images/logo houses of pets white transparent.png';
import iStore from './Images/ios.png';
import aStore from './Images/android.png';

const Footer = () => {
  return (
    <footer className="footer" id='footer'>
      <div className="footer-container">
        <img src={logo} alt="Logo" className="footer-logo" />
        <div className="footer-section">
          <h4>À propos de nous</h4>
          <p>
            HOP (Houses Of Pets) connecte les propriétaires d'animaux avec des gardiens de confiance, 
            assurant des services fiables de garde et de promenade d'animaux
          </p>
        </div>
        <div className="footer-section">
          <h4>Informations de contact</h4>
          <p>7 BOULEVARD SAINT-MICHEL 75005 PARIS</p>
          <p>Phone: +33 6 10 39 22 45</p>
          <p>contact@housesofpets.com.</p>
        </div>
        <div className="footer-section">
          <h4>Suivez-nous</h4>
          <div className="footer-social">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </div>
          <h4 className='telecharger'>bientôt disponible en téléchargement sur : </h4>
          <div className="store">
            <a href="https://apps.apple.com/us/app/houseofpets/id123456789" target="_blank" rel="noopener noreferrer">
              <img src={iStore} alt="Télécharger sur l'App Store" className="iStore" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.houseofpets.app&pli=1" target="_blank" rel="noopener noreferrer">
              <img src={aStore} alt="Obtenez-le sur Google Play" className="aStore" />
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h4>Liens utiles</h4>
          <ul>
            <li><a href="/privacy.html">Politique de confidentialité</a></li>
            <li><a href="/terms.html">Termes et Conditions</a></li>
            <li><a href="/remove.html">Politique de suppression de compte</a></li>
            <li><a href="/eula.html">EULA</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Houses Of Pets. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;