import React from 'react';
import './License-sitter.css';
import Footer from '../../FooterSitter/FooterSitter';
import Navbar from '../../NavbarSitter/NavbarSitter';


const Licensesitter = () => {
    return (
        <div className='license-sitter'>
        <Navbar />
        <div className="license-page-sitter">
            
            <h1>Accord de Licence Utilisateur Final (EULA)</h1>
            <p><strong>Dernière mise à jour : 02 Juillet 2024</strong></p>
            <p>Cet Accord de Licence Utilisateur Final ("Accord") est un contrat juridiquement contraignant entre vous ("Utilisateur Final" ou "vous") et HOP (Houses Of Pets) ("Licencieur", "nous" ou "notre"), concernant votre utilisation de l'application HOP ("Application"). En téléchargeant, installant ou utilisant l'Application, vous acceptez de respecter les termes de cet Accord.</p>

            <h2>Table des matières</h2>
            <ol>
                <li><a href="#1">L'Application</a></li>
                <li><a href="#2">Portée de la Licence</a></li>
                <li><a href="#3">Exigences Techniques</a></li>
                <li><a href="#4">Maintenance et Support</a></li>
                <li><a href="#5">Utilisation des Données</a></li>
                <li><a href="#6">Contributions Générées par l'Utilisateur</a></li>
                <li><a href="#7">Licence de Contribution</a></li>
                <li><a href="#8">Responsabilité</a></li>
                <li><a href="#9">Garantie</a></li>
                <li><a href="#10">Réclamations Produit</a></li>
                <li><a href="#11">Conformité Légale</a></li>
                <li><a href="#12">Informations de Contact</a></li>
                <li><a href="#13">Résiliation</a></li>
                <li><a href="#14">Termes et Conditions de Tiers et Bénéficiaire</a></li>
                <li><a href="#15">Droits de Propriété Intellectuelle</a></li>
                <li><a href="#16">Loi Applicable</a></li>
                <li><a href="#17">Divers</a></li>
                <li><a href="#18">Acceptation des Termes</a></li>
            </ol>

            <h2 id="1">1. L'Application</h2>
            <p>HOP est une plateforme conçue pour connecter les propriétaires d'animaux avec des gardiens de confiance. L'application permet de réserver des services de garde d'animaux et de promenade.</p>

            <h2 id="2">2. Portée de la Licence</h2>
            <p>Vous bénéficiez d'une licence non transférable, non exclusive et non sous-licenciable pour installer et utiliser l'Application sur tout appareil que vous possédez ou contrôlez, conformément aux règles d'utilisation d'Apple et de Google.</p>

            <h2 id="3">3. Exigences Techniques</h2>
            <p>L'Application nécessite la version 1.0.0 du firmware ou une version ultérieure. Nous vous recommandons d'utiliser la version la plus récente du firmware.</p>

            <h2 id="4">4. Maintenance et Support</h2>
            <p>Nous sommes responsables de la maintenance et du support de l'Application. Vous pouvez nous contacter à l'adresse email fournie dans les descriptions de l'App Store ou du Play Store.</p>

            <h2 id="5">5. Utilisation des Données</h2>
            <p>Nous pouvons accéder et ajuster le contenu téléchargé de votre Application et vos informations personnelles, conformément à notre politique de confidentialité.</p>

            <h2 id="6">6. Contributions Générées par l'Utilisateur</h2>
            <p>L'Application peut vous inviter à soumettre du contenu, tel que des textes, des photos ou des vidéos. En soumettant ce contenu, vous nous accordez une licence mondiale, non exclusive, pour l'utiliser, le reproduire et le distribuer.</p>
            <p>Nous avons une politique de tolérance zéro pour le contenu inacceptable ou abusif. Tout contenu jugé inapproprié, offensant, illégal, ou en violation des droits de tiers sera supprimé, et l'utilisateur responsable sera banni de la plateforme.</p>

            <h2 id="7">7. Licence de Contribution</h2>
            <p>En publiant des contributions, vous nous accordez une licence mondiale, irrévocable et non exclusive pour utiliser, reproduire, modifier et distribuer ce contenu.</p>

            <h2 id="8">8. Responsabilité</h2>
            <p>Nous ne sommes responsables qu'en cas de négligence grave ou intentionnelle. Notre responsabilité est limitée aux dommages typiques et prévisibles.</p>

            <h2 id="9">9. Garantie</h2>
            <p>Nous garantissons que l'Application est exempte de logiciels malveillants au moment du téléchargement et qu'elle fonctionne comme décrit dans la documentation.</p>

            <h2 id="10">10. Réclamations Produit</h2>
            <p>Nous sommes responsables de toute réclamation liée à l'Application, y compris les réclamations de conformité aux exigences légales ou réglementaires.</p>

            <h2 id="11">11. Conformité Légale</h2>
            <p>Vous garantissez que vous n'êtes pas situé dans un pays sous embargo du gouvernement américain ou figurant sur la liste des parties interdites ou restreintes.</p>

            <h2 id="12">12. Informations de Contact</h2>
            <p>Pour toute question ou réclamation, veuillez nous contacter à : <a href="mailto:contact@housesofpets.com">contact@housesofpets.com</a></p>

            <h2 id="13">13. Résiliation</h2>
            <p>La licence est valable jusqu'à sa résiliation par vous ou par nous. En cas de non-respect des termes de cet Accord, vos droits de licence seront automatiquement résiliés.</p>

            <h2 id="14">14. Termes et Conditions de Tiers et Bénéficiaire</h2>
            <p>Nous respecterons les termes des accords tiers lors de l'utilisation de l'Application. Apple et Google, ainsi que leurs filiales, sont des tiers bénéficiaires de cet Accord.</p>

            <h2 id="15">15. Droits de Propriété Intellectuelle</h2>
            <p>Nous sommes responsables de toute réclamation d'infraction de propriété intellectuelle liée à l'Application.</p>

            <h2 id="16">16. Loi Applicable</h2>
            <p>Cet Accord est régi par les lois de la France, à l'exclusion de ses règles de conflits de lois.</p>

            <h2 id="17">17. Divers</h2>
            <p>Si une disposition de cet Accord est jugée invalide, les autres dispositions resteront en vigueur. Les accords collatéraux doivent être faits par écrit.</p>

            <h2 id="18">18. Acceptation des Termes</h2>
            <p>En utilisant notre Application, vous acceptez de respecter notre EULA. Vous reconnaissez que toute violation de ces termes, y compris la soumission de contenu inacceptable ou abusif, entraînera des mesures disciplinaires, y compris la résiliation de votre compte.</p>
        </div>
        <Footer />
        </div>
    );
};

export default Licensesitter;