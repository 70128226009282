import React from 'react';
import './FooterSitter.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import logo from './Images/logo houses of pets white transparent.png';
import iStore from './Images/ios.png';
import aStore from './Images/android.png';

const FooterSitter = () => {
  return (
    <footer className="footer-sitter" id='footer-sitter'>
  <div className="footer-container-sitter">
    <img src={logo} alt="Logo" className="footer-logo-sitter" />
    <div className="footer-section-sitter">
      <h4>À propos de nous</h4>
      <p>
        HOP (Houses Of Pets) connecte les propriétaires d'animaux avec des gardiens de confiance, 
        assurant des services fiables de garde et de promenade d'animaux
      </p>
    </div>
    <div className="footer-section-sitter">
      <h4>Informations de contact</h4>
      <p>7 BOULEVARD SAINT-MICHEL 75005 PARIS</p>
      <p>Phone: +33 6 10 39 22 45</p>
      <p>contact@housesofpets.com.</p>
    </div>
    <div className="footer-section-sitter">
      <h4>Suivez-nous</h4>
      <div className="footer-social-sitter">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebookF />
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <FaLinkedinIn />
        </a>
      </div>
      <h4 className='telecharger-sitter'>bientôt disponible en téléchargement sur : </h4>
      <div className="store-sitter">
        <a href="https://apps.apple.com/us/app/houseofpets/id123456789" target="_blank" rel="noopener noreferrer">
          <img src={iStore} alt="Télécharger sur l'App Store" className="iStore-sitter" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.houseofpets.app&pli=1" target="_blank" rel="noopener noreferrer">
          <img src={aStore} alt="Obtenez-le sur Google Play" className="aStore-sitter" />
        </a>
      </div>
    </div>
    <div className="footer-section-sitter">
      <h4>Liens utiles</h4>
      <ul>
        <li><a href="/hopper/privacy.html">Politique de confidentialité</a></li>
        <li><a href="/hopper/terms.html">Termes et Conditions</a></li>
        <li><a href="/hopper/remove.html">Politique de suppression de compte</a></li>
        <li><a href="/hopper/eula.html">EULA</a></li>
      </ul>
    </div>
  </div>
  <div className="footer-bottom-sitter">
    <p>&copy; 2024 Houses Of Pets. All rights reserved.</p>
  </div>
</footer>
  );
};

export default FooterSitter;