import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sitter from './SittersPage/Pages/Sitter/Sitter';
import Owner from './OwnersPage/Pages/Owner/Owner';
import Privacy from './OwnersPage/Pages/Extra/Privacy/Privacy';
import Privacysitter from './SittersPage/Pages/Extra-sitter/Privacy-sitter/Privacy-sitter';
import Politique from './OwnersPage/Pages/Extra/Politique/Politique';
import Termes from './OwnersPage/Pages/Extra/Terms/Terms';
import License from './OwnersPage/Pages/Extra/License/License';
import Politiquesitter from './SittersPage/Pages/Extra-sitter/Politique-sitter/Politique-sitter';
import Termssitter from './SittersPage/Pages/Extra-sitter/Terms-sitter/Terms-sitter';
import Licensesitter from './SittersPage/Pages/Extra-sitter/License-sitter/License-sitter';


function App() {
  return (
    <Router>
      <div className="App">
        
        <Routes>
        <Route path='/' element={<Owner/>} />
        <Route path="/privacy.html" element={<Privacy />} />
        <Route path="/remove.html" element={<Politique />}/>
        <Route path="/terms.html" element={<Termes />} />
        <Route path="/eula.html" element={<License/>} />
        <Route path="/hopper" element={<Sitter />} />
        <Route path="/hopper/terms.html" element={<Termssitter/>} />
        <Route path="/hopper/privacy.html" element={<Privacysitter />}/> 
        <Route path="/hopper/remove.html" element={<Politiquesitter />}/>
        <Route path="/hopper/eula.html" element={<Licensesitter />}/>  
        

          
          
        </Routes>
      </div>
    </Router>
  );
}




export default App;