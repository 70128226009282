import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import logo from './Images/logo houses of pets black transparent.png';

const Navbar = () => {
    const [menu, setMenu] = useState("Accueil");
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="header-container">
            <img src={logo} alt="Logo HOP" className='logo' />
            <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                <Link to='/' onClick={() => { setMenu("Accueil"); scrollToTop(); setIsMenuOpen(false); }} className={menu === "Accueil" ? "active" : ""}>Accueil</Link>
                <a href='#container-offer' onClick={() => { setMenu("Télécharger application"); setIsMenuOpen(false); }} className={menu === "Télécharger application" ? "active" : ""}>Télécharger application</a>
                <a href='#service' onClick={() => { setMenu("Services"); setIsMenuOpen(false); }} className={menu === "Services" ? "active" : ""}>Services</a>
                <a href='#propos-container' onClick={() => { setMenu("Pourquoi HOP"); setIsMenuOpen(false); }} className={menu === "Pourquoi HOP" ? "active" : ""}>Pourquoi HOP</a>
            </div>
            <div className="right-side">
                <Link to='/hopper' onClick={() => { setMenu("Sitters"); scrollToTop(); }} className={menu === "Sitters" ? "active sitters" : "sitters"}>Je suis un pet hoster</Link>
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;