import React from 'react';
import './Acceuilsitter.css';

const Acceuil = () => {
  return (
    <div className='Acceuil-sitter'>
      <div className='Acceuil-main-sitter'>
        <div className='heading-container-sitter'>
          <h1 className='main-heading-sitter'>Gagner de l'argent</h1>
          <p className='description-sitter'>en s’amusant avec les animaux</p>
          {/* Uncomment the lines below to include the app store images */}
          {/* <div className='buttons-sitter'>
            <img src={appStoreImage} alt="Télécharger sur l'App Store" className="str1-sitter" />
            <img src={googlePlayImage} alt="Obtenez-le sur Google Play" className="str2-sitter" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Acceuil;