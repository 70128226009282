import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Service.css';
import image1 from './Images/image1.png';
import image2 from './Images/image2.png';
import image3 from './Images/image3.png';
import image4 from './Images/image4.png';
import image5 from './Images/image5.png';

const Service = () => {
  const [isSwiperVisible, setIsSwiperVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setIsSwiperVisible(true);
      } else {
        setIsSwiperVisible(false);
      }
    };

    handleResize(); // Check the initial width
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='service' id='service'>
      <div className="service-container">
        <h1>
          Découvrez des services personnalisés, pratiques et sûrs pour prendre soin de votre animal de compagnie !
        </h1>
        {isSwiperVisible ? (
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={1}
            autoplay={{ delay: 9000 }}
            pagination={{ clickable: true }}
            navigation
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="service-item item1">
                <div className="service-image-container shape-background">
                  <img src={image1} alt="Garde d'animaux" className="service-image" />
                </div>
                <div className="service-text">
                  <h2>GARDE D'ANIMAUX</h2>
                  <p>Réservez un pet sitter de confiance pour garder votre animal à son domicile ou chez vous.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="service-item item2">
                <div className="service-image-container shape-background">
                  <img src={image2} alt="Balades" className="service-image" />
                </div>
                <div className="service-text">
                  <h2>BALADES</h2>
                  <p>Offrez à votre compagnon des promenades régulières et stimulantes pour son bien-être physique et mental.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="service-item item3">
                <div className="service-image-container shape-background">
                  <img src={image3} alt="Visites à domicile" className="service-image" />
                </div>
                <div className="service-text">
                  <h2>VISITES À DOMICILE</h2>
                  <p>Idéal pour les animaux plus âgés ou ceux qui préfèrent rester dans leur environnement familier. Nos pet sitters viennent nourrir, jouer et câliner votre animal à domicile.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="service-item item4">
                <div className="service-image-container shape-background">
                  <img src={image4} alt="Toilettage" className="service-image" />
                </div>
                <div className="service-text">
                  <h2>TOILETTAGE</h2>
                  <p>Réservez des séances de toilettage professionnel directement depuis l'application pour garder votre animal propre et confortable.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="service-item item5">
                <div className="service-image-container shape-background">
                  <img src={image5} alt="Le vétérinaire" className="service-image" />
                </div>
                <div className="service-text">
                  <h2>LE VÉTÉRINAIRE</h2>
                  <p>Besoin d'accompagnement pour une visite médicale ? Nos pet sitters peuvent transporter votre animal chez le vétérinaire et vous tenir informé du déroulement de la consultation.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        ) : (
          <div className="service-static-layout">
            <div className="service-item item1">
              <div className="service-image-container shape-background">
                <img src={image1} alt="Garde d'animaux" className="service-image" />
              </div>
              <div className="service-text">
                <h2>GARDE D'ANIMAUX</h2>
                <p>Réservez un pet sitter de confiance pour garder votre animal à son domicile ou chez vous.</p>
              </div>
            </div>
            <div className="service-item item2">
              <div className="service-image-container shape-background">
                <img src={image2} alt="Balades" className="service-image" />
              </div>
              <div className="service-text">
                <h2>BALADES</h2>
                <p>Offrez à votre compagnon des promenades régulières et stimulantes pour son bien-être physique et mental.</p>
              </div>
            </div>
            <div className="service-item item3">
              <div className="service-image-container shape-background">
                <img src={image3} alt="Visites à domicile" className="service-image" />
              </div>
              <div className="service-text">
                <h2>VISITES À DOMICILE</h2>
                <p>Idéal pour les animaux plus âgés ou ceux qui préfèrent rester dans leur environnement familier. Nos pet sitters viennent nourrir, jouer et câliner votre animal à domicile.</p>
              </div>
            </div>
            <div className="service-item item4">
              <div className="service-image-container shape-background">
                <img src={image4} alt="Toilettage" className="service-image" />
              </div>
              <div className="service-text">
                <h2>TOILETTAGE</h2>
                <p>Réservez des séances de toilettage professionnel directement depuis l'application pour garder votre animal propre et confortable.</p>
              </div>
            </div>
            <div className="service-item item5">
              <div className="service-image-container shape-background">
                <img src={image5} alt="Le vétérinaire" className="service-image" />
              </div>
              <div className="service-text">
                <h2>LE VÉTÉRINAIRE</h2>
                <p>Besoin d'accompagnement pour une visite médicale ? Nos pet sitters peuvent transporter votre animal chez le vétérinaire et vous tenir informé du déroulement de la consultation.</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Service;