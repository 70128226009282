import React from 'react';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';
import './Privacy.css';

const Privacy = () => {
  return (
    <div>
      <Navbar />
      <div className="privacy-page">
        <h1>Politique de confidentialité</h1>
        <p className="update-date">Dernière mise à jour : 30 Août 2023</p>
        <p>
          Cette Politique de confidentialité décrit nos politiques et procédures concernant la collecte, l'utilisation et la divulgation de vos informations lorsque vous utilisez l'application HOP (Houses Of Pets) et vous informe sur vos droits en matière de confidentialité et sur la protection légale de vos données.
        </p>
        <h2>Collecte des informations personnelles</h2>
        <p>L'application HOP collecte les informations personnelles suivantes auprès des utilisateurs via le formulaire d'inscription, et ces informations peuvent être modifiées ou complétées ultérieurement dans l'espace profil :</p>
        <ul>
          <li>Votre nom</li>
          <li>Votre adresse e-mail</li>
          <li>Votre numéro de téléphone</li>
          <li>Votre photo de profil</li>
          <li>Votre ville et pays de résidence</li>
          <li>Les informations concernant vos animaux de compagnie, y compris le nom, l'adresse, les informations sur les vaccins, etc.</li>
        </ul>
        <h2>Utilisation des informations personnelles</h2>
        <p>Les informations personnelles collectées sont utilisées par l'application HOP dans les buts suivants :</p>
        <ul>
          <li>Mettre en relation les Hopers et les Pet Hosters pour faciliter la garde et la promenade d'animaux de compagnie.</li>
          <li>Gérer les réservations et les demandes de service.</li>
          <li>Envoyer des notifications aux deux parties concernant les réservations et les mises à jour de l'application.</li>
        </ul>
        <h2>Stockage et protection des informations personnelles</h2>
        <p>Les informations personnelles sont stockées sur des serveurs sécurisés situés en Europe. Les données sont cryptées et protégées par des restrictions d'accès, une gestion de sauvegardes régulières et une politique de sécurité stricte pour empêcher tout accès, utilisation ou divulgation non autorisés.</p>
        <h2>Tiers impliqués dans le traitement des données</h2>
        <p>Aucun tiers n'est impliqué dans le traitement des données des utilisateurs, à l'exception du partenaire de paiement, Stripe, qui intervient uniquement dans l'étape de paiement pour traiter les données de paiement en toute sécurité directement avec l'utilisateur.</p>
        <h2>Cookies et technologies de suivi</h2>
        <p>L'application HOP utilise des cookies, Google Analytics, Facebook Pixel et des outils d'analyse marketing pour améliorer ses services. Les utilisateurs peuvent gérer les cookies via les paramètres de leur navigateur.</p>
        <h2>Accès, modification et suppression des informations personnelles</h2>
        <p>Les utilisateurs peuvent accéder, modifier ou supprimer leurs informations personnelles à tout moment via l'espace profil de l'application.</p>
        <h2>Contact</h2>
        <p>Si vous avez des questions concernant cette Politique de confidentialité, vous pouvez nous contacter par e-mail à l'adresse suivante : <a href="mailto:contact@housesofpets.com">contact@housesofpets.com</a>.</p>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;