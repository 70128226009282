import React from 'react';
import './Politique-sitter.css';

import Footer from '../../FooterSitter/FooterSitter';
import Navbar from '../../NavbarSitter/NavbarSitter';

const Politiquesitter = () => {
    return (
        <div>
            <Navbar />
            <div className="politique-page-sitter">
                <h1>Demande de Suppression de Compte pour l'application Houses Of Pets</h1>
                <p>Nous nous engageons à protéger votre vie privée et à vous donner le contrôle sur vos données. Si vous souhaitez supprimer votre compte Houses Of Pets, veuillez suivre les étapes ci-dessous :</p>
                <ol>
                    <li><strong>Ouvrez l'application Houses Of Pets sur votre appareil mobile.</strong></li>
                    <li><strong>Allez dans "Mon Profil" puis sélectionnez "Supprimer mon compte".</strong></li>
                    <li><strong>Suivez les instructions à l'écran pour confirmer la suppression de votre compte.</strong></li>
                </ol>
                <p>Une fois votre demande soumise, notre équipe backoffice examinera votre demande dans un délai de 24 à 72 heures. Une fois votre demande approuvée, les données suivantes seront supprimées de nos systèmes :</p>
                <ul>
                    <li>Nom et prénom</li>
                    <li>Adresse email</li>
                    <li>Mot de passe</li>
                    <li>Adresses enregistrées</li>
                    <li>Comptes de paiement</li>
                </ul>
                <h2>Durée de conservation des données</h2>
                <p>Veuillez noter que certaines données peuvent être conservées pour se conformer aux exigences légales, réglementaires et autres obligations. Ces données seront conservées dans un format qui ne permet pas de vous identifier personnellement.</p>
                <h2>Contact</h2>
                <p>Si vous avez des questions ou des préoccupations concernant la suppression de votre compte ou notre politique de confidentialité, veuillez nous contacter à <a href="mailto:mariyem@housesofpets.com">mariyem@housesofpets.com</a>.</p>
            </div>
            <Footer />
        </div>
    );
};

export default Politiquesitter;