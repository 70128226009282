import './Inscription.css';
import ios from './Images/ios.png';
import android from './Images/android.png';

const RegistrationForm = () => {
  return (
    <div className='main-i'>
      <h2>bientôt disponible en téléchargement sur :</h2>
      <div className="store-b">
        <img src={ios} alt="App Store" className="store-i" />
        <img src={android} alt="Google Play" className="store-i" />
      </div>
    </div>
  );
};

export default RegistrationForm;