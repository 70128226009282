import React from 'react';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';
import './Terms.css';

const Terms = () => {
    return (
        <div>
            <Navbar />
            <div className="termes-conditions-page">
                <h1>Termes et Conditions</h1>
                <p>Les utilisateurs doivent accepter les règles d'utilisation de l'application HOP pour utiliser le service, notamment :</p>
                <ul>
                    <li>Respecter les autres utilisateurs.</li>
                    <li>Ne pas publier de contenu inapproprié.</li>
                    <li>Ne pas communiquer leurs informations de paiement ou les coordonnées de contact dans les discussions.</li>
                    <li>Ne pas vendre des produits ou services via la plateforme sans prévenir les administrateurs.</li>
                </ul>
                <h2>Mise en relation entre les Hopers et les Pet Hosters</h2>
                <p>Les Hopers peuvent consulter la liste des Pet Hosters, consulter leur profil pour voir les informations et les évaluations, puis demander la prestation de service. Le Pet Hoster peut accepter ou décliner la réservation.</p>
                <h2>Tarifs et conditions financières</h2>
                <p>L'inscription est gratuite, mais HOP prend un pourcentage de la transaction payé par le Hoper. Le Pet Hoster précise son prix par service et HOP ajoute un pourcentage. Le paiement s'effectue par carte bancaire, et HOP verse le prix de la prestation au Pet Hoster par période.</p>
                <h2>Résolution des litiges</h2>
                <p>En cas de litige entre un Hoper et un Pet Hoster, HOP essaiera de le résoudre de manière amicale et peut éventuellement rembourser le Hoper.</p>
                <h2>Politique de remboursement</h2>
                <p>Le remboursement est possible en cas d'annulation ou d'insatisfaction des services proposés par les Pet Hosters, sous réserve que HOP seule peut décider selon les données qu'elle a de la réservation émise.</p>
                <h2>Gestion des évaluations et des commentaires</h2>
                <p>Les Hopers et les Pet Hosters peuvent évaluer les uns les autres, et les commentaires sont publiés en temps réel. HOP se réserve le droit de supprimer ou de modifier un commentaire en cas de non-appropriation du contenu et se réserve le droit de désactiver le compte de son propriétaire en cas d'avis diffamatoires ou abusifs.</p>
                <h2>Droits de propriété intellectuelle</h2>
                <p>Les droits de propriété intellectuelle associés à l'application HOP sont normaux et protégés par la loi applicable.</p>
                <h2>Contact</h2>
                <p>Si vous avez des questions concernant ces Termes et Conditions, vous pouvez nous contacter par e-mail à l'adresse suivante : <a href="mailto:contact@housesofpets.com">contact@housesofpets.com</a>.</p>
            </div>
            <Footer />
        </div>
    );
};

export default Terms;