import React from 'react';
import './Acceuil.css';
/*
import appStoreImage from './ios.png';
import googlePlayImage from './android.png';
*/
const Acceuil = () => {
  return (
    <div className='Main'>
      <div className='Acceuil'>
        <div className='Acceuil-main'>
          <div className='heading-container'>
            <h1 className='main-heading'>À la recherche d'une personne <br />de confiance pour garder votre <br />animal de compagnie ?</h1>
            <p className='description'>Un service tout en un, sécurisé et personnalisé.</p>
            {/*
            <div className='buttons'>
              <img src={appStoreImage} alt="Télécharger sur l'App Store" className="str1" />
              <a href="https://play.google.com/store/apps/details?id=com.houseofpets.app&pli=1" target="_blank" rel="noopener noreferrer">
                <img src={googlePlayImage} alt="Obtenez-le sur Google Play" className="str2" />
              </a>
            </div>
            */}
          </div>
        </div>
      </div>
      <div className='white-page'></div>
    </div>
  );
};

export default Acceuil;